@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');

:root {
	--newBg: #F8F8F8;
	--mainBg: #edf8ff;
	--darkBlue: #147cbc;
	--blueDark: #1B47B4;
	--blue: #0873b9;
	--blueish: #f2faff;
	--lightBlue: #edf8ff;
	--softBlue: #e8f4ff;
	--blackest: #141414;
	--black: #000;
	--black333: #333;
	--black2: #222;
	--black3: rgba(20, 20, 20, 0.7);
	--gray: #5a5a5a;
	--darkPurple: #7d5ae2;
	--lightPurple: #e3e1ff;
	background-color: var(--newBg) !important;
	font-family: 'DM Sans', sans-serif !important;
	overflow: hidden;
}

html {
	background-color: var(--newBg) !important;
	font-family: 'DM Sans', sans-serif !important;
}

body {
	background-color: var(--newBg) !important;
	font-family: 'DM Sans', sans-serif !important;
}

textarea {
	border-radius: 8px !important;
	border: 1px solid #000 !important;
}

label.ant-form-item-required {
	color: var(--text-black, #222);
	font-family: 'DM Sans', sans-serif !important;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}

label {
	font-family: 'DM Sans', sans-serif !important;
	font-style: normal !important;
	font-weight: 500 !important;
	color: var(--Text-Primary, #212121) !important;
	font-size: 1rem !important;
	line-height: 1.5rem !important;
}

ul {
	list-style-type: disc;
	margin-left: 15px;
	margin-top: 10px;
}

li {
	margin-bottom: 5px;
}

ol li {
	list-style: decimal;
	list-style-position: inside;
}

.no-number li {
	list-style: none;
}

a {
	color: #1677ff;
}

blockquote {
	margin: 0;
}

blockquote {
	padding: 15px;
	background: #eee;
	border-radius: 5px;
}

blockquote::before {
	content: '\201C';
}

blockquote::after {
	content: '\201D';
}

.ant-form-item-required::before {
	content: '' !important;
}

.ant-form-item-required::after {
	content: '*' !important;
	visibility: visible !important;
}

.ant-drawer-header-title {
	flex-direction: row-reverse;
}

.job-drawer .ant-drawer-header {
	display: none !important;
}

.job-drawer .ant-drawer-body {
	padding: 0 !important;
	overflow: hidden !important;
}

.ant-modal-root {
	/* height: 100vh !important; */
}

.ant-modal-wrap {
	min-height: 100vh !important;
	/* height: 100% !important; */
	/* overflow-y: hidden !important; */
	position: relative !important;
}

.ant-modal-content {
	padding: 0 !important;
	z-index: 1000;
	max-height: 100%;
	height: auto;
	border-radius: 0.3125rem !important;
}

.ant-modal-body {
	min-width: 37.875rem !important;
	max-height: 100% !important;
	height: 40rem !important;
	overflow-y: auto !important;
	padding: 20px 37px !important;
	background: inherit;
	border-radius: 0.875rem !important;
}


.ant-modal {
	display: flex !important;
	justify-content: center !important;
	margin-top: -5rem !important;
}

.modal-btn {
	top: -850px !important;
}

.confirm-job {
	width: auto !important;
}

.confirm-job .ant-modal-content {
	/* min-height: 900px; */
	height: 100%;
}

.confirm-job .ant-modal-wrap {
	width: 100% !important;
	height: 100% !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.confirm-job .ant-modal-body {
	min-width: 800px !important;
	width: 100% !important;
}

@media (max-width: 481px) {
	.confirm-job .ant-modal-body {
		min-width: 100% !important;
		width: 100% !important;
	}

	.ant-modal-body {
		min-width: 100% !important;
		max-height: 100% !important;
		width: 100% !important;
		height: 40rem !important;
		overflow-y: auto !important;
		padding: 10px 17px !important;
		background: inherit;
		border-radius: 0.875rem !important;
	}

	.ant-modal-content {
		width: 100% !important;
	}

	.ant-modal-confirm .ant-modal-confirm-body>.anticon {
		margin-inline-end: 0px !important;
	}
}

.share-modal .ant-modal-header {
	display: none !important;
}

.share-modal .ant-modal-content {
	border-radius: 0.875rem !important;
	background: #FFF !important;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-close-x {
	color: white !important;
	font-weight: 500;
}

.ant-modal-title {
	background: var(--blueDark);
	color: white !important;
	padding: 20px 17px;
	font-size: 22px;
	font-weight: 500;
	letter-spacing: -0.462px;
	border-radius: 5px 5px 0px 0px;
}

.search-select .ant-select-selector {
	border: none !important;
}

.search-select .ant-select-selection-placeholder {
	color: #333 !important;
}

.ant-select-selection-search-input {
	--tw-ring-color: none !important;
	--tw-ring-offset-shadow: none !important;
	--tw-ring-shadow: none !important;
	box-shadow: none !important;
	border-color: none !important;
	background: inherit !important;
}

.ant-modal-footer {
	padding: 20px !important;
}

.ant-input-number-input {
	height: 42px !important;
}

.ant-input-outlined:disabled {
	color: #000 !important;
}

.ant-input-number-disabled {
	color: #000 !important;
}

.ant-select-disabled .ant-select-selector {
	color: #000 !important;
}

.ant-input-number-handler-wrap {
	display: none !important;
}

.ant-upload-icon>.anticon,
.ant-upload-list-item-name {
	color: #0a0a0a !important;
}

.ant-tabs-tab {
	background-color: transparent !important;
	margin-right: 3.75rem !important;
	border-bottom: 0px solid rgba(216, 216, 216, 0.54) !important;
}

.ant-tabs-tab-active {
	background-color: var(--bs-dark) !important;
	color: var(--bs-orange) !important;
}

.ant-tabs-tab-active span {
	color: var(--bs-orange) !important;
	padding-left: 5px !important;
	padding-right: 5px !important;
}

.ant-tabs-tab-btn span {
	color: #000 !important;
	font-size: 17px !important;
	font-style: normal;
	font-weight: 700 !important;
	line-height: 24.5px;
	/* 116.667% */
}

.ant-picker-range {
	width: 100% !important;
}

.editable-cell {
	position: relative;
}

.editable-cell-value-wrap {
	padding: 5px 0px;
	cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
	padding: 4px 11px;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
}

.ant-radio-inner {
	border-color: #147cbc !important;
}

.ant-table .ant-table-container .ant-table-content table thead.ant-table-thead .ant-table-cell {
	background-color: #f2faff;
	color: #000;

	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 31.5px */
	border: none !important;
}

.no-header-bg-table .ant-table-container .ant-table-content table thead.ant-table-thead .ant-table-cell {
	white-space: nowrap !important;
	min-width: 50px !important;
	width: fit-content !important;
	background-color: transparent;
	color: #000;
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 31.5px */
	border: none !important;
}

:where(.css-dev-only-do-not-override-3mqfnx).ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
	border-start-start-radius: 0px;
}

:where(.css-dev-only-do-not-override-3mqfnx).ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
	border-start-end-radius: 0px;
}

.ant-table-tbody>tr>td {
	color: #141414;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
}

.editor .ant-table-tbody>tr>td {
	border: .2px solid var(--Gray-200, #EAECF0) !important;
	padding: 5px 8px !important;
}

.editor .ant-table-measure-row {
	display: none !important;
}

table td:not(:first-child) {
	min-width: 150px !important;
}

.ant-table-title {
	width: 100%;
	/* height: 44px;
	background-color: var(--darkBlue); */
}

.ant-table-footer {
	background: transparent !important;
	padding: 9px !important;
	border: 0px !important;
}

.ant-tabs-tab-active span {
	color: var(--darkBlue);
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 31.5px */
}

.ant-tabs-tab-btn span {
	color: var(--black333);
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 31.5px */
}

.ant-tabs-tab-btn .material-icons {
	color: var(--black333) !important;
}

.ant-tabs-tab-active .material-icons {
	color: var(--darkBlue) !important;
}

.app-status-tab .ant-tabs-nav-list {
	width: 100%;
	justify-content: space-between;
}

.app-status-tab .ant-tabs-nav-wrap {
	background: #fff !important;
}

.app-status-tab .ant-tabs-tab {
	padding: 12px 25px !important;
}

.ant-tabs-ink-bar {
	width: fit !important;
	height: 0.3125rem !important;
	background: var(--blueDark) !important;
	border-radius: 0.5rem !important;
}

.tabless .ant-tabs-nav-wrap {
	display: none !important;
}

.tabless .ant-tabs-nav {
	display: none !important;
}

.jobs-tab .ant-tabs-nav {
	margin-left: 2.5rem !important;
	padding: 0rem 0rem .8rem !important;
}

.ant-menu-item-selected {
	background: #f5fbffba !important;
}

.ant-menu-item {
	color: var(--Inactive-menu-item, #7792b7);
}

.ant-menu-item .ant-menu-item-icon {
	fill: var(--Inactive-menu-item, #7792b7);
}

.ant-menu-item-selected span {
	color: var(--darkBlue) !important;
}

.ant-menu-item-selected .ant-menu-item-icon {
	fill: var(--darkBlue) !important;
	color: var(--darkBlue) !important;
}

.ant-menu {
	border-inline-end: none !important;
	background: transparent !important;
}

.notification-dropdown>.ant-dropdown-menu {
	min-height: 500px !important;
	height: 80vh !important;
	overflow-y: scroll !important;
	padding: 0px !important;
}

.notification-dropdown .ant-dropdown-menu-item {
	padding: 0px !important;
	margin: 5px !important;
}

.ant-dropdown-menu {
	padding: 20px !important;
}

.ant-dropdown-menu-item {
	margin: 20px 10px !important;
}

.profile-dropdown>.ant-dropdown-menu {
	padding: 0 !important;
}

.profile-dropdown .ant-dropdown-menu-item {
	margin: 0 !important;
}

.profile-dropdown .ant-dropdown-menu-item:hover {
	background-color: transparent !important;
}

.ant-steps-item-wait .ant-steps-item-icon {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	width: 3rem !important;
	height: 3rem !important;
	border-radius: 50% !important;
	margin-inline-start: 0px !important;
	background: #EDF8FF !important;
	border-color: var(--blueDark) !important;
	border: 1px solid var(--blueDark) !important;
	z-index: 10 !important;
}

.ant-steps-item-process .ant-steps-item-icon {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	width: 3rem !important;
	height: 3rem !important;
	border-radius: 50% !important;
	margin-inline-start: 0px !important;
	background: var(--blueDark) !important;
	border-color: var(--blueDark) !important;
	border: 1px solid var(--blueDark) !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	width: 3rem !important;
	height: 3rem !important;
	border-radius: 50% !important;
	margin-inline-start: 0px !important;
	background: var(--blueDark) !important;
	border-color: var(--blueDark) !important;
	border: 1px solid var(--blueDark) !important;
}

.ant-steps-icon {
	top: 3.5px !important;
	color: var(--nea-blue, #147cbc) !important;
	font-family: 'DM Sans', sans-serif !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 1rem !important;
	line-height: 1.5rem !important;
}

.ant-steps-item-tail {
	top: 20% !important;
	padding: 4px 14px !important;
	margin-inline-start: 90.19px !important;
	/* height: 1px !important;
	background: var(--darkBlue) !important; */
}

.ant-steps-item-tail::after {
	height: 0.0625rem !important;
	background: var(--blueDark) !important;
	border-radius: 1px;
}

.ant-steps-item-container {
	width: 100% !important;
	display: flex !important;
	flex-direction: column !important;
	justify-content: center !important;
	align-items: center !important;
}

.ant-steps-item-content {
	text-align: left !important;
}

.ant-steps-item-title {
	display: flex !important;
	width: 100%;
	color: var(--blueDark) !important;
	text-align: center !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 1.5rem !important;
}

.ant-steps-item-finish .ant-steps-icon {
	color: #fff !important;
	font-size: 18px !important;
	font-style: normal;
	font-weight: 500 !important;
	line-height: 150%;
	/* 33px */
	letter-spacing: -0.242px;
}

.ant-picker {
	max-width: 100%;
	width: 100%;
	height: 3.25rem !important;
	align-items: center;
	border-radius: 0.375rem !important;
	border: 1px solid #212121 !important;
	background: var(--White, #fff);
	box-shadow: none !important;
}

.doc-date-picker {
	height: 64px !important;
}

.doc-date-picker-cv {
	height: 44px !important;
}

.ant-checkbox-inner {
	width: 28px !important;
	height: 28px !important;
	margin-top: 1px !important;
	border-radius: 4px !important;
	border: 1px solid var(--nea-blue, #147cbc) !important;
}

.ant-checkbox .ant-checkbox-inner::after {
	width: 8px !important;
	height: 12px !important;
}

.ant-upload-drag {
	border-radius: 1px !important;
	border: 1px dashed var(--nea-blue, #147cbc) !important;
	background: var(--White, #fff) !important;
	height: 64px !important;
}

.att-docs-dragger .ant-upload-drag {
	min-width: 300px !important;
}

.table-dragger .ant-upload-drag {
	min-width: 150px !important;
}

.table-dragger .ant-upload {
	min-width: 150px !important;
	height: 44px !important;
	padding: 0px !important;
}

.ant-upload-text {
	color: var(--nea-blue, #147cbc) !important;
	font-size: 18px !important;
	font-style: normal;
	font-weight: 500 !important;
	line-height: normal;
}

.upload-input .ant-upload-drag {
	border-radius: 8px !important;
	border: 1px solid var(--black) !important;
	background: var(--White, #fff) !important;
	height: 44px !important;
}

.upload-input .ant-upload-drag .ant-upload {
	padding: 0px !important;
}

.cv-dragger .ant-upload-drag {
	border-radius: 0px;
	border: none !important;
	background: transparent !important;
	height: 300px !important;
}

.cv-dragger .ant-upload {
	padding: 0px !important;
}

.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
	width: 120px !important;
	height: 120px !important;
}

.ant-spin-dot-item {
	background-color: white !important;
}

.blue-spin .ant-spin-dot-item {
	background-color: #1B47B4 !important;
}

.spin-white .ant-spin-dot-item {
	background-color: var(--darkBlue) !important;
}

.ant-tag {
	color: #262168 !important;
	font-size: 15px !important;
	font-weight: 500 !important;
	background: #e3e1ff !important;
}

.ant-tag-close-icon {
	color: #262168 !important;
}

.job-filter input::placeholder {
	color: #9b9b9e !important;
	font-size: 16px !important;
	font-style: normal;
	font-weight: 700 !important;
	line-height: 24.5px;
	/* 116.667% */
}

.job-tab .ant-tabs-nav {
	max-width: 36.6rem !important;
	width: 100%;
}

.job-tab .ant-tabs-tab {
	background-color: transparent !important;
}

.job-tab .ant-tabs-nav-wrap {
	width: 100% !important;
	background-color: transparent !important;
	padding: 0px !important;
}

.job-tab .ant-tabs-tab-active {
	background-color: var(--bs-dark) !important;
	color: var(--bs-orange) !important;
	border-bottom: 5px solid #147cbc !important;
}

.job-tab .ant-tabs-tab-active>span {
	color: #1B47B4 !important;
}

.job-tab .ant-tabs-ink-bar {
	display: none;
}

.job-tab .ant-tabs-nav-list {
	width: 100% !important;
	display: flex !important;
	flex-direction: row !important;
	justify-content: space-between !important;
}

@media (max-width: 481px) {
	.ant-tabs-nav-wrap {
		overflow: scroll !important;
	}
}

.ant-picker-dropdown {
	width: 300px;
}

.ant-picker-footer {
	width: 300px !important;
}

.ant-picker-ok .ant-btn {
	color: var(--black);
	border: 2px solid blue;
	width: 50px;
}

.ant-switch {
	background: #e2e8f0;
}

.ant-switch-checked {
	background: var(--darkBlue) !important;
}

.ant-switch:hover {
	border: 1px solid #64748b !important;
	background: #fff !important;
}

.ant-switch-handle::before {
	background-color: #64748b !important;
}

.ant-switch-checked .ant-switch-handle::before {
	background-color: #fff !important;
}

.step1-select-icon .anticon {
	color: var(--darkBlue);
}

.step1-select-tag.ant-select-multiple .ant-select-selection-item {
	background: #dbebf7;
	color: var(--darkBlue);
	border-radius: 36px;
	height: 30px;
	display: flex;
	align-items: center;
}

.step1-select .ant-checkbox-inner {
	width: 18px !important;
	height: 18px !important;
}

/* .ant-progress-text {
	display: flex;
	justify-content: center;
	color: #fff !important;
}

.ant-progress-inner {
	width: 57px !important;
	height: 57px !important;
	position: absolute !important;
	left: 73% !important;
	top: 15% !important;
	font-size: 21px !important;
}

.profile-progress .ant-progress-outer {
	background: #fff !important;
}

.profile-progress .ant-progress-inner {
	position: initial !important;
	width: 100% !important;
	height: 7px !important;
	bottom: 0 !important;
	left: 0 !important;
	border-radius: 0px !important;
}

.profile-progress .ant-progress-bg {
	height: 7px !important;
	border-radius: 0px !important;
	background: #147CBC !important;
} */

.ant-float-btn {
	z-index: 10000;
	background-color: #C8100B !important;
	width: 123px !important;
	height: 130px !important;
	top: 255px !important;
}

.ant-float-btn-body {
	background-color: transparent !important;
}

.audio-recorder {
	background-color: transparent !important;
	box-shadow: none !important;
	border-radius: 0px !important;
	color: var(--darkBlue) !important;
	/* width: 30px !important; */
	display: flex;
	align-items: center;
	transition: all .2s ease-in;
	-webkit-tap-highlight-color: transparent;
}

.audio-recorder-mic {
	height: 20px !important;
	color: var(--darkBlue) !important;
}

.react-tel-input .form-control {
	height: 100% !important;
	width: 100% !important;
	border: none !important;
	outline: none !important;
	padding-left: 75px !important;
}

.flag-dropdown {
	padding: 10px !important;
}

.ant-badge-count {
	font-weight: 500 !important;
	background: var(--darkBlue) !important;
	box-shadow: none !important;
}

.ant-badge-dot {
	top: 0.45rem !important;
	left: 1.2rem !important;
}


.cv_title {
	color: var(--Primary-White, #FFF);
	font-family: "Satoshi";
	font-size: 2rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.cv_subtitle {
	font-family: "Satoshi";
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.cv_header_1 {
	font-family: "Satoshi";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

}

.cv-diver.ant-divider {
	border-block-start: 2px solid #222 !important;
}

.cv-diver-prof.ant-divider {
	border-block-start: 1px solid #FAAD14 !important;
}

.in_app_bar {
	display: flex;
	align-items: center;
	padding: 1rem;
	gap: .75rem;
	padding-right: 3.81rem;
	min-height: 3.75rem;
	height: auto;
	/* flex-shrink: 0 !important; */
	border-radius: 0.625rem;
	background: #FFF;
	color: var(--Text-Primary, #212121);
	font-family: 'Satoshi';
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	border-top-left-radius: 1rem;
	border-bottom-left-radius: 1rem;
}