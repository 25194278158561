.slanted-background {
    background-blend-mode: normal;
    background: url('../../assets/landing/home29.png');
    background-color: #052B85;
    background-size: 50% auto, 100% 100%;
    background-repeat: no-repeat;
    background-position: start;
    width: 100%;
    height: 100%;
    padding-bottom: 3rem;
}

.login_title {
    color: var(--Primary-White, #FFF);
    font-family: "Satoshi";
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

